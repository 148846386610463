// text_image_divider max 42rem

.textimagedivider {
    display: block;

    .textimagedivider_image {

        img {
            position: relative;
            height: auto;
            object-fit: unset;
        }
    }

    .textimagedivider_text {

        .textimagedivider_textcontent {
            transform: unset;
            top: unset;
        }
    }
}