// roundimage_text max 42rem

.workflow {

  .workflow_container {
    display: block;

    .workflow_item {
      width: 12rem;
      margin: auto;

      img, .dummy {
        height: 12rem;
      }

      .workflow_overlay {
        height: 12rem;
        background-color: rgba(0,0,0,0);

        .workflow_overlay_text {
          display: block;
        }
      }

      h3 {
        margin: 2rem -5rem 1rem -5rem;
      }

      p {
        margin: 0 -5rem 5rem -5rem;
      }
    }

    .workflow_line {
      display: none;
    }
  }
}