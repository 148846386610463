//default max 42rem

html {

  body {

    h2 {

      em {
        font-size: 2rem;
      }
    }
  }
}


